/*------------------------------------*\
    # modules.wrapper
\*------------------------------------*/

.wrapper {
    max-width: 1596px;
    margin: 0 auto;
    width: 100%;

    padding-left: calc($unit * 6);
    padding-right: calc($unit * 6);

    @media (--screen-from-med) {
        padding-left: calc($unit * 14);
        padding-right: calc($unit * 14);
    }

    @media (--screen-from-lrg) {
        max-width: 1692px;
        padding-left: calc($unit * 40);
        padding-right: calc($unit * 40);
    }

    &--medium {
        max-width: 1100px;
    }

    &--small {
        max-width: 846px;
        padding-left: calc($unit * 6);
        padding-right: calc($unit * 6);
    }
}
