/*------------------------------------*\
    # views.hashnet
\*------------------------------------*/

.hashnet {
    &__header {
        lost-utility: clearfix;

        &__copy {
            lost-column: 8/12;
            lost-offset: 2/12;
        }

        &__content {
            text-align: center;
            @media (--screen-from-lrg) {
                column-count: 2;
                text-align: left;
            }
        }

        &__image {
            @media (--screen-to-med) {
                display: none;
            }
            @media (--screen-to-lrg) {
                text-align: center;
                margin-bottom: calc($unit * 4);
            }
            @media (--screen-from-lrg) {
                lost-column: 7/12;
                lost-offset: 6/12;
                margin-bottom: calc(-$unit * 25);
            }
        }

        &__button {
            padding-top: calc($unit * 4);

            @media (--screen-to-lrg) {
                text-align: center;
            }
        }
    }

    &__features {
        &__wrapper {
            padding: calc($unit * 16) 0 calc($unit * 2) 0;

            @media (--screen-from-med) {
                padding: calc($unit * 30) 0 calc($unit * 2) 0;
            }

            @media (--screen-from-lrg) {
                padding: calc($unit * 62) 0 calc($unit * 2) 0;
            }
        }

        &__header {
            @media (--screen-to-med) {
                margin-bottom: calc($unit * 16);
            }
            @media (--screen-from-med) {
                lost-column: 3/12;
                lost-offset: 2/12;
                margin-bottom: calc($unit * 40);
            }
        }

        &__feature {
            lost-utility: clearfix;

            margin-bottom: calc($unit * 20);

            @media (--screen-from-lrg) {
                margin-bottom: calc($unit * 62);
            }

            &__content {
                @media (--screen-from-med) {
                    lost-column: 8/12;
                    lost-offset: 2/12;
                }

                @media (--screen-from-lrg) {
                    lost-column: 6/12;
                    lost-offset: 0/12;
                    lost-move: -4/12;
                }

                @media (--screen-from-xlrg) {
                    lost-column: 4/12;
                    lost-offset: 0/12;
                    lost-move: -1/12;
                }
            }

            &__visual {
                & > img {
                    margin-bottom: calc($unit * 4);

                    @media (--screen-to-lrg) {
                        max-width: 180px;
                    }

                    /*filter: invert(1) grayscale(1) contrast(1.0);*/
                }

                /*.light & img {
                    filter: none;
                }*/

                @media (--screen-from-med) {
                    lost-column: 3/12;
                    lost-offset: 2/12;
                }

                @media (--screen-from-lrg) {
                    lost-column: 4/12;
                    lost-offset: 0/12;
                    lost-move: 8/12;
                }

                @media (--screen-from-xlrg) {
                    lost-column: 3/12;
                    lost-offset: 0/12;
                    lost-move: 7/12;
                }
            }
        }
    }

    &__masternodes {
        lost-utility: clearfix;

        &__general {
            position: relative;

            &__header {
                position: absolute;
                top: 0;
                z-index: 4;
                margin-top: calc($unit * 20);

                @media (--screen-from-med) {
                    lost-offset: 2/12;
                    margin-top: calc($unit * 40);
                }

                h1 {
                    max-width: 12em;
                }
            }

            &__map {
                position: relative;
                margin-bottom: calc($unit * 20);

                width: 100%;
                height: 400px;

                @media (--screen-from-med) {
                    height: 100vh;
                    width: 100%;
                }
            }

            &__content {
                @media (--screen-from-lrg) {
                    lost-column: 8/12;
                    lost-offset: 2/12;
                }

                @media (--screen-from-xlrg) {
                    lost-column: 4/12;
                    lost-offset: 2/12;
                }

                &__intro {
                    margin-bottom: calc($unit * 12);
                }

                &__features {
                    lost-utility: clearfix;
                    margin-bottom: calc($unit * 10);

                    @media (--screen-from-lrg) {
                        lost-offset: 2/12;
                    }

                    & > div {
                        margin-bottom: calc($unit * 20);

                        @media (--screen-to-sml) {
                            text-align: center;
                        }

                        @media (--screen-from-sml) {
                            lost-column: 6/12;
                        }

                        @media (--screen-from-lrg) {
                            lost-column: 3/12;
                        }
                    }
                }
            }

            &__icon {
                display: inline-block;
                border: 1px solid color($color-primary a(0.7));
                background: color($color-primary a(0.05));
                width: 100px;
                height: 100px;
                position: relative;
                border-radius: $global-rounded;
                margin-bottom: calc($unit * 4);

                &:after {
                    content: '';
                    position: absolute;
                    top: -7px;
                    left: -7px;
                    width: 112px;
                    height: 112px;
                    border: 1px solid color($color-primary a(0.3));
                    border-radius: $global-rounded;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &__label {
                max-width: 16em;

                @media (--screen-to-sml) {
                    margin: 0 auto;
                }
            }
        }

        &__become {
            lost-utility: clearfix;
            margin-bottom: calc($unit * 10);

            @media (--screen-from-med) {
                margin-bottom: calc($unit * 20);
            }

            &__header {
                @media (--screen-from-lrg) {
                    lost-offset: 2/12;
                }
                margin-bottom: calc($unit * 16);

                @media (--screen-from-med) {
                    margin-bottom: calc($unit * 20);
                }

                @media (--screen-from-lrg) {
                    margin-bottom: calc($unit * 32);
                }

                h1 {
                    max-width: 12em;
                }
            }
            &__intro {
                margin-bottom: calc($unit * 12);

                @media (--screen-from-lrg) {
                    lost-offset: 2/12;
                    lost-column: 8/12;
                }

                @media (--screen-from-xlrg) {
                    lost-column: 4/12;
                    lost-offset: 0/12;
                }
            }
            &__apply {
                margin-bottom: calc($unit * 12);

                @media (--screen-from-lrg) {
                    lost-offset: 2/12;
                }

                @media (--screen-from-xlrg) {
                    lost-column: 4/12;
                    lost-offset: 2/12;
                }
            }

            &__content {
                @media (--screen-from-lrg) {
                    lost-offset: 2/12;
                    lost-column: 8/12;
                }

                @media (--screen-from-xlrg) {
                    lost-column: 4/12;
                    lost-offset: 6/12;
                }

                &__features {
                    lost-utility: clearfix;
                    lost-offset: 2/12;

                    & > div {
                        lost-column: 2/12;
                    }
                }
            }

            &__list {
                &__title {
                    margin-bottom: calc($unit * 8);

                    @media (--screen-from-sml) {
                        max-width: 12em;
                    }
                }
                &__label {
                    @mixin type-scale $type-tiny, 5;
                    font-family: $ff-sans-wide;
                    color: $color-secondary;
                    margin-bottom: calc($unit * 2);

                    @media (--screen-from-xsml) {
                        margin-bottom: calc($unit * 6);
                        lost-column: 4/12;
                    }

                    @media (--screen-from-lrg) {
                        lost-column: 6/12;
                    }
                }
                &__value {
                    @mixin type-scale $type-small, 5;
                    margin: 0;
                    margin-bottom: calc($unit * 6);

                    @media (--screen-from-xsml) {
                        lost-column: 8/12;
                    }

                    @media (--screen-from-lrg) {
                        lost-column: 6/12;
                    }
                }
            }
        }
    }
}

.hashnet__status {
    padding: calc($unit * 10) 0;

    @media (--screen-from-med) {
        padding: calc($unit * 40) 0;
    }
}

.hashnet__status__section {
    margin-bottom: calc($unit * 10);

    @media (--screen-from-med) {
        margin-bottom: calc($unit * 40);
    }
}

.hashnet__valuation {
    opacity: 0;
    padding-top: calc($unit * 10);

    @media (--screen-from-med) {
        padding-top: calc($unit * 30);
    }
}

.hashnet__exchanges {
    max-width: 640px;
    margin-bottom: calc($unit * 6);
}

.hashnet__status__section-title {
    margin-bottom: calc($unit * 15);
}
