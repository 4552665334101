/*------------------------------------*\
    # views.transparency
\*------------------------------------*/

.transparency {
    &__whitepaper {
        lost-utility: clearfix;

        padding: calc($unit * 8) 0 calc($unit * 24) 0;

        @media (--screen-from-med) {
            padding: calc($unit * 28) 0 calc($unit * 62) 0;
        }

        &__header {
            margin-bottom: calc($unit * 8);
        }

        &__wrapper {
            @media (--screen-from-lrg) {
                lost-offset: 2/12;
                lost-column: 8/12;
            }
        }

        &__content {
            &__split {
                @media (--screen-from-med) {
                    column-count: 2;
                    column-gap: 40px;
                }
            }
        }
    }

    &__governance,
    &__eligibility {
        lost-utility: clearfix;

        &__header {
            lost-utility: clearfix;
            margin-bottom: calc($unit * 24);

            h1 {
                @media (--screen-from-med) {
                    max-width: 12em;
                }
            }
        }

        &__wrapper {
            padding: calc($unit * 8) 0 calc($unit * 16) 0;

            @media (--screen-from-med) {
                padding: calc($unit * 62) 0 calc($unit * 28) 0;
            }

            @media (--screen-from-lrg) {
                lost-offset: 2/12;
                lost-column: 8/12;
            }
        }

        &__content {
            lost-utility: clearfix;

            @media (--screen-to-lrg) {
                margin-bottom: calc($unit * 12);
            }

            &__item {
                position: relative;

                svg {
                    position: absolute;
                    top: 0;
                    left: 0;

                    @media (--screen-from-lrg) {
                        top: -4px;
                        left: -60px;
                    }
                }

                @media (--screen-to-lrg) {
                    padding-left: calc($unit * 20);
                    margin-bottom: calc($unit * 12);
                }

                @media (--screen-from-lrg) {
                    margin-bottom: calc($unit * 12);
                }
                @media screen and (min-width: 1390px) {
                    lost-column: 5/12 2;
                    margin-bottom: calc($unit * 24);
                }
            }
        }

        &__paragraph {
            margin-top: calc($unit * 6);
            
            @media screen and (min-width: 1390px) {
                lost-column: 5/12;
            }
        }
    }

    &__eligibility {
        &__wrapper {
            padding: calc($unit * 8) 0 calc($unit * 16) 0;

            @media (--screen-from-med) {
                padding: calc($unit * 28) 0 calc($unit * 28) 0;
            }
        }
    }
}
