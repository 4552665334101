/*------------------------------------*\
    # utils.opacity
\*------------------------------------*/

.u-opacity {
    &--90 {
        opacity: 0.9;
    }

    &--70 {
        opacity: 0.7;
    }

    &--60 {
        opacity: 0.6;
    }

    &--50 {
        opacity: 0.5;
    }

    &--40 {
        opacity: 0.5;
    }

    &--30 {
        opacity: 0.3;
    }

    &--15 {
        opacity: 0.15;
    }
}
