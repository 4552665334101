/*------------------------------------*\
    # views.team
\*------------------------------------*/

.team {

    &__widget {
        lost-utility: clearfix;

        padding: 0 0 calc($unit * 25) 0;
        
        @media (--screen-to-med) {
            text-align: center;
        }

        @media (--screen-from-med) {
            padding: 0 0 calc($unit * 62) 0;
        }

        &__header {
            @media (--screen-to-lrg) {
                margin-bottom: calc($unit * 16);
            }

            @media (--screen-from-lrg) {
                lost-offset: 1/12;
                lost-column: 3/12;
            }
            @media (--screen-from-xxlrg) {
                lost-offset: 2/12;
                lost-column: 3/12;
            }
        }

        &__content {
            @media (--screen-from-lrg) {
                lost-column: 6/12;
            }
            @media (--screen-from-xlrg) {
                lost-column: 5/12;
            }
        }
    }

    &__member-info {
        margin-bottom: calc($unit * 5);

        @media (--screen-from-med) {
            display: flex;
            margin-bottom: calc($unit * 10);
        }

        &:hover {
            .team__member-info__image img {
                filter: grayscale(0);
            }
    
            .team__member-info__image:after {
                transform: rotate(135deg);
                border-top: 1px solid color($color-primary a(1));
                border-right: 1px solid transparent;
                border-bottom: 1px solid color($color-primary a(1));
                border-left: 1px solid color($color-primary a(1));
            }
        }

        &__image {
            margin-bottom: calc($unit * 4);
            position: relative;
            display: inline-block;

            @media (--screen-to-med) {
                width: 80px;
                height: 80px;
                padding: 10px 0 0 0;
            }

            @media (--screen-from-med) {
                flex: 0 0 140px;
                margin-right: calc($unit * 8);
                padding: 10px;
            }

            &:after {
                content: '';
                display: block;
    
                width: 80px;
                height: 80px;
    
                position: absolute;
                top: 0;
                left: 0;
    
                transform: rotate(45deg);
                transition: all .4s ease-out;
    
                border-top: 1px solid color($color-primary a(0.5));
                border-right: 1px solid transparent;
                border-bottom: 1px solid color($color-primary a(0.5));
                border-left: 1px solid color($color-primary a(0.5));
                border-radius: 50%;

                @media (--screen-from-med) {
                    width: 120px;
                    height: 120px;
                }
            }

            img {
                width: 100px;
                height: auto;
                border-radius: 50%;
                filter: grayscale(1);
                
                @media (--screen-to-lrg) {
                    width: 60px;
                }
            }
        }

        &__description {
            @media (--screen-to-lrg) {
                max-width: 34em;
                margin: 0 auto;
            }
        }

        &__content {
            @media (--screen-from-med) {
                flex: 1 0 0;
            }
        }

        &__position {
            @mixin type-scale $type-small, 0.75;
        }

        &__name {
            margin-bottom: calc($unit * 3);

            @media (--screen-from-med) {
                margin-bottom: calc($unit * 5);
            }
        }
    }


    &__intro {
        lost-utility: clearfix;

        @media (--screen-from-lrg) {
            padding-bottom: calc($unit * 60);
        }

        &__header {
            padding: calc($unit * 10) 0;

            @media (--screen-from-med) {
                padding: calc($unit * 30) 0;
            }
            
            @media (--screen-from-lrg) {
                padding: calc($unit * 60) 0;
            }
            

            &__content {
                @media (--screen-to-lrg) {
                    margin-bottom: calc($unit * 10);
                }

                @media (--screen-from-lrg) {
                    lost-column: 8/12;
                    lost-offset: 1/12;
                }
            }

            &__action {
                @media (--screen-from-lrg) {
                    lost-column: 3/12;
                    padding-top: calc($unit * 10);
                }
            }    
        }
    }

    &__section {
        &--tech,
        &--bizz,
        &--advisors {
            padding-bottom: calc($unit * 10);

            @media (--screen-from-med) {
                padding-bottom: calc($unit * 30);
            }

            @media (--screen-from-lrg) {
                padding-bottom: calc($unit * 60);
            }
        }
    }

}