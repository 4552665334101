/*------------------------------------*\
    # components.section-bar
\*------------------------------------*/

.section-bar {
    display: none;

    @media (--screen-from-med) {
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;
    }

    &__wrapper {
        @media (--screen-from-med) {
            position: fixed;
            z-index: 2;
            right: calc($unit * 8);
            height: 100vh;
        }

        @media (--screen-from-lrg) {
            right: calc($unit * 15);
        }
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        stroke: white;
        transition: all 0.15s ease-in-out;
    }

    &__item {
        display: block;
        margin-top: -1px;
        padding: calc($unit * 4);
        text-align: center;
        border: 1px solid #3f414c;
        transition: all 0.15s ease-in-out;

        &__label {
            position: absolute;
            top: 0;
            right: 59px;
            padding: calc($unit * 4) 0;
            width: 0;
            border: 1px solid #3f414c;
            background: $color-bg-alt;
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            white-space: nowrap;
            transition: all 0.15s ease-in-out;
        }

        &:hover {
            .section-bar__icon {
                stroke: $color-primary;
            }

            .section-bar__item__label {
                padding: calc($unit * 4) calc($unit * 6);
                width: auto;
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
