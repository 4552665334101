/*------------------------------------*\
    # utils.type
\*------------------------------------*/

.u-type {
    &--left {
        text-align: left;
    }

    &--center {
        text-align: center;
    }

    &--right {
        text-align: right;
    }

    &--upper {
        text-transform: uppercase;
    }

    &--typography {
        &--wide {
            font-family: $ff-sans-wide;
        }
        &--condensed {
            font-family: $ff-sans-condensed;
        }
        &--compressed {
            font-family: $ff-sans-compressed;
        }
    }

    &--weight {
        &--ultra {
            font-weight: $type-weight-ultra;
        }
        &--thin {
            font-weight: $type-weight-thin;
        }
        &--light {
            font-weight: $type-weight-light;
        }
    }

    &--spacing {
        &--narrow {
            letter-spacing: -0.05em;
        }
        &--wide {
            letter-spacing: 0.15em;
        }
        &--extra-wide {
            letter-spacing: 0.3em;
        }
    }

    /* Sizes */

    &--alpha {
        @mixin type-scale $type-base, 6;

        @media (--screen-from-xsml) {
            @mixin type-scale $type-medium, 8;
        }
        @media (--screen-from-sml) {
            @mixin type-scale $type-xlarge, 10;
        }
        @media (--screen-from-med) {
            @mixin type-scale $type-xxlarge, 14;
        }
        @media (--screen-from-lrg) {
            @mixin type-scale $type-xxxlarge, 19;
        }
    }

    &--beta {
        @mixin type-scale $type-medium, 9;
        @media (--screen-from-sml) {
            @mixin type-scale $type-xlarge, 12;
        }
        @media (--screen-from-med) {
            @mixin type-scale $type-xxlarge, 14;
        }
    }

    &--gamma {
        @mixin type-scale $type-base, 6;
        @media (--screen-from-xsml) {
            @mixin type-scale $type-medium, 8;
        }
        @media (--screen-from-med) {
            @mixin type-scale $type-large, 10;
        }
        @media (--screen-from-med) {
            @mixin type-scale $type-xlarge, 12;
        }
    }

    &--delta {
        @mixin type-scale $type-base, 6;
        @media (--screen-from-xsml) {
            @mixin type-scale $type-medium, 9;
        }
        @media (--screen-from-med) {
            @mixin type-scale $type-large, 10;
        }
    }

    &--epsilon {
        @mixin type-scale $type-base, 7;
        @media (--screen-from-med) {
            @mixin type-scale $type-medium, 9;
        }
    }

    &--zeta {
        @mixin type-scale $type-small, 6;
        @media (--screen-from-med) {
            @mixin type-scale $type-base, 7;
        }
    }

    &--eta {
        @mixin type-scale $type-tiny, 5;
        @media (--screen-from-med) {
            @mixin type-scale $type-small, 6;
        }
    }

    &--huge {
        @mixin type-scale $type-xxlarge, 14;

        @media (--screen-from-med) {
            @mixin type-scale $type-xxxlarge, 19;
        }
        @media (--screen-from-lrg) {
            @mixin type-scale $type-huge, 19;
        }
    }

    &--micro {
        @mixin type-scale $type-tiny, 5;
    }

    &--nano {
        @mixin type-scale $type-micro, 5;
    }

    /* Colors */
    &--primary {
        color: $color-primary;
    }

    &--secondary {
        color: $color-text-light;
    }

    &--tertiary {
        color: $color-tertiary;
    }

    &--complementary {
        color: $brand-complementary;
    }

    &--negative {
        color: white;
    }

    &--light-color {
        color: $color-text-light;
    }

    /* Shorten long links */
    &--shorten {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }

    &--wrap {
        overflow-wrap: break-word;
        word-wrap: break-word;
    }

    &--warning {
        color: $color-warning;
    }

    &--success {
        color: $color-success;
    }
}
