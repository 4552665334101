/*------------------------------------*\
    # modules.hamburger
\*------------------------------------*/

.hamburger {
    display: inline-block;
    width: 14px;
    height: 11px;
    margin-right: 4px;
    transition: transform 330ms ease-out;
    
    &.is-open {
        transform: rotate(-45deg);
    }
    
    .line-menu {
        background-color: $color-primary;
        width: 100%;
        height: 1px;
        margin-bottom: 3px;
    }

    .line-menu.half {
        width: 50%;
    }
    
    .line-menu.start {
        transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
        transform-origin: right;
        margin-bottom: 3px;
    }
    
    &.is-open .line-menu.start {
        transform: rotate(-90deg) translateX(3px);
    }
    
    .line-menu.end {
        margin-left: 7px;
        transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
        transform-origin: left;
    }
    
    &.is-open .line-menu.end {
        transform: rotate(-90deg) translateX(-3px);
    }
}
    
    
    