/*------------------------------------*\
    # components.timeline
\*------------------------------------*/

.timeline {
    position: relative;
    width: 240px;
    
    margin: 0 auto;

    @media (--screen-from-xsml) {
        width: 300px;
    }

    @media (--screen-from-sml) {
        width: 440px;
    }

    @media (--screen-from-lrg) {
        width: 880px;
    }

    li:last-child {
        padding-bottom: calc($unit * 16);

        @media (--screen-from-lrg) {
            padding-bottom: calc($unit * 32);
        }
    }

    &--first {
        padding-bottom: calc($unit * 32);

        &:before {
            content: '';
            display: block;
            
            width: 1px;
            position: absolute;
            left: 0;
            top: 42px;
            bottom: 0;
            transform: translateX(-50%);
    
            width: 1px;
            background-color: $color-primary;
    
            @media (--screen-from-lrg) {
                left: 50%;
                bottom: 10px;
            }
        }
    }

    &--last {
        /* Last timeline has a dot on the end of line */
        &:after {
            content: '';
            display: block;

            position: absolute;
            left: 0;
            bottom: 0;
            transform: translateX(-50%);

            width: 8px;
            height: 8px;
            background-color: $color-primary;
            border-radius: 50%;

            @media (--screen-from-lrg) {
                left: 50%;
            }
        }
    }

    &__topdecoration {
        display: block;

        position: absolute;
        margin: 0 auto;
        top: 0;
        left: 0;
        transform: translateX(-50%);

        width: 46px;
        height: 43px;
        background: url(../../main/assets/icons/icon-timeline-top.svg) no-repeat;

        @media (--screen-from-lrg) {
            left: 50%;
        }
    }

    &__item {
        position: relative;
        padding-bottom: calc($unit * 3);

        @media (--screen-from-lrg) {
            padding-bottom: calc($unit * 8);
            overflow: hidden;
        }

        &:before {
            content: '';
            display: block;
    
            position: absolute;
            left: 0;
            top: 42px;
            bottom: 0;
            transform: translateX(-50%);
    
            width: 1px;
            background-color: $color-primary;
    
            @media (--screen-from-lrg) {
                left: 50%;
                top: 42px;
                bottom: 0;
            }
        }

        &--title {
            @media (--screen-to-lrg) {
                min-height: auto;
            }

            @media (--screen-from-lrg) {
                margin-bottom: -52px;
                min-height: 42px;
                padding-bottom: 0;
            }

            &:before {
                @media (--screen-to-lrg) {
                    top: 0;
                }
            }
        }

        &__month {
            @mixin type-scale $type-small, 5;
            letter-spacing: .05em;

            @media (--screen-to-lrg) {
                display: block;
                padding-left: calc($unit * 10);
            }

            @media (--screen-from-lrg) {
                padding: calc($unit * 3) calc($unit * 10) calc($unit * 6) 0;
                text-align: right;
                float: left;
                width: 440px;
            }
        }

        &__circle {
            display: block;

            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-50%);

            width: 42px;
            height: 42px;
            background: url(../../main/assets/icons/icon-timeline-item-upcoming.svg) no-repeat;

            &--complete {
                background: url(../../main/assets/icons/icon-timeline-item-complete.svg) no-repeat;
            }

            @media (--screen-from-lrg) {
                left: 50%;
            }
        }

        &__content {
            @mixin type-scale $type-small, 5;
            font-family: $ff-sans-wide;
            /*color: color($color-text-light a(0.8));*/

            @media (--screen-to-lrg) {
                padding: calc($unit * 3) 0 calc($unit * 2) calc($unit * 10);
                display: block;
            }

            @media (--screen-from-lrg) {
                padding: calc($unit * 3) 0 calc($unit * 6) calc($unit * 10);
                float: right;
                width: 440px;
            }
        }

        &__title {
            @media (--screen-to-lrg) {
                display: block;
                padding: 0 0 0 calc($unit * 10);
            }

            @media (--screen-from-lrg) {
                position: absolute;
                top: 0;
            }
        }
    }
}