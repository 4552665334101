/*------------------------------------*\
# utils.grid
\*------------------------------------*/

.u-grid {
    @mixin clearfix;

    &-sml {
        @media (--screen-to-sml) {
            &__col1 {
                lost-column: 1/12;
            }

            &__col2 {
                lost-column: 2/12;
            }

            &__col3 {
                lost-column: 3/12;
            }

            &__col4 {
                lost-column: 4/12;
            }

            &__col5 {
                lost-column: 5/12;
            }

            &__col6 {
                lost-column: 6/12;
            }

            &__col7 {
                lost-column: 7/12;
            }

            &__col8 {
                lost-column: 8/12;
            }

            &__col9 {
                lost-column: 9/12;
            }

            &__col10 {
                lost-column: 10/12;
            }

            &__col11 {
                lost-column: 11/12;
            }

            &__col12 {
                width: 100%;
            }
        }
    }

    &-med {
        @media (--screen-from-med) {
            &__col1 {
                lost-column: 1/12;
            }

            &__col2 {
                lost-column: 2/12;
            }

            &__col3 {
                lost-column: 3/12;
            }

            &__col4 {
                lost-column: 4/12;
            }

            &__col5 {
                lost-column: 5/12;
            }

            &__col6 {
                lost-column: 6/12;
            }

            &__col7 {
                lost-column: 7/12;
            }

            &__col8 {
                lost-column: 8/12;
            }

            &__col9 {
                lost-column: 9/12;
            }

            &__col10 {
                lost-column: 10/12;
            }

            &__col11 {
                lost-column: 11/12;
            }

            &__col12 {
                width: 100%;
            }
        }
    }

    &-lrg {
        @media (--screen-from-lrg) {
            &__col1 {
                lost-column: 1/12;
            }

            &__col2 {
                lost-column: 2/12;
            }

            &__col3 {
                lost-column: 3/12;
            }

            &__col4 {
                lost-column: 4/12;
            }

            &__col5 {
                lost-column: 5/12;
            }

            &__col6 {
                lost-column: 6/12;
            }

            &__col7 {
                lost-column: 7/12;
            }

            &__col8 {
                lost-column: 8/12;
            }

            &__col9 {
                lost-column: 9/12;
            }

            &__col10 {
                lost-column: 10/12;
            }

            &__col11 {
                lost-column: 11/12;
            }

            &__col12 {
                width: 100%;
            }
        }
    }

    &-xlrg {
        @media (--screen-from-xlrg) {
            &__col1 {
                lost-column: 1/12;
            }

            &__col2 {
                lost-column: 2/12;
            }

            &__col3 {
                lost-column: 3/12;
            }

            &__col4 {
                lost-column: 4/12;
            }

            &__col5 {
                lost-column: 5/12;
            }

            &__col6 {
                lost-column: 6/12;
            }

            &__col7 {
                lost-column: 7/12;
            }

            &__col8 {
                lost-column: 8/12;
            }

            &__col9 {
                lost-column: 9/12;
            }

            &__col10 {
                lost-column: 10/12;
            }

            &__col11 {
                lost-column: 11/12;
            }

            &__col12 {
                width: 100%;
            }
        }
    }

    &-xxlrg {
        @media (--screen-from-xxlrg) {
            &__col1 {
                lost-column: 1/12;
            }

            &__col2 {
                lost-column: 2/12;
            }

            &__col3 {
                lost-column: 3/12;
            }

            &__col4 {
                lost-column: 4/12;
            }

            &__col5 {
                lost-column: 5/12;
            }

            &__col6 {
                lost-column: 6/12;
            }

            &__col7 {
                lost-column: 7/12;
            }

            &__col8 {
                lost-column: 8/12;
            }

            &__col9 {
                lost-column: 9/12;
            }

            &__col10 {
                lost-column: 10/12;
            }

            &__col11 {
                lost-column: 11/12;
            }

            &__col12 {
                width: 100%;
            }
        }
    }

}