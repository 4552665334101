/*------------------------------------*\
    # utils.display
\*------------------------------------*/

.u-display {
    &--b {
        display: block;
    }

    &--ib {
        display: inline-block;
    }

    &--f {
        display: flex;
    }

    &--fc {
        display: flex;
        flex-direction: column;
    }

    &--n {
        display: none;
    }
}
