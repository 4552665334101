/*------------------------------------*\
    # modules.fieldset
\*------------------------------------*/

.fieldset {

    /**
     * Fieldset base
     */
    padding: 0;
    margin: 0;
    border: 0;

    &__legend {
        padding: calc($unit * 8) 0;

        @mixin type-scale $type-small, 1;
        letter-spacing: .15em;
        text-transform: uppercase;
        font-weight: $type-weight-bold;
    }

}