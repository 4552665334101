/*------------------------------------*\
    # views.tokensale
\*------------------------------------*/

.tokensale {
    /* Lists styling in tokensale page */
    &__list {
        lost-utility: clearfix;

        &__label {
            @mixin type-scale $type-tiny, 5;
            font-family: $ff-sans-wide;
            overflow: hidden;
            margin: 0 0 calc($unit * 4) 0;
            padding-right: calc($unit * 4);
            float: left;
            width: 85%;

            @media (--screen-from-xsml) {
                float: left;
                width: 90%;
            }

            &.indented {
                opacity: 0.6;
                padding-left: calc($unit * 5);
            }

            .wide-list & {
                width: 65%;

                @media (--screen-from-xsml) {
                    width: 70%;
                }

                @media (--screen-from-med) {
                    width: 80%;
                }

                @media (--screen-from-lrg) {
                    width: 70%;
                }
            }
        }

        &__value {
            @mixin type-scale $type-tiny, 5;
            margin: 0 0 calc($unit * 4) 0;
            padding: 0;
            float: left;
            width: 15%;

            @media (--screen-from-xsml) {
                float: left;
                width: 10%;
            }

            @media (--screen-from-lrg) {
                @mixin type-scale $type-small, 6;
            }

            &.indented {
                opacity: 0.6;
            }

            .wide-list & {
                width: 35%;

                @media (--screen-from-xsml) {
                    width: 30%;
                }

                @media (--screen-from-med) {
                    width: 20%;
                }

                @media (--screen-from-lrg) {
                    width: 30%;
                }
            }
        }
    }

    &__tabs {
        margin-bottom: calc($unit * 3);
        
        &__tab {
            display: inline-block;
            padding: 0 calc($unit * 3) calc($unit * 2) calc($unit * 3);
            color: #ccc;
            position: relative;
            opacity: 0.7;

            /*.light & {
                color: $color-text;

                &:after {
                    background: color($color-bg-alt a(0.3));
                }
            }*/

            &.current {
                color: $color-primary;
                opacity: 1;

                &:after {
                    background: $color-primary;
                }
            }

            &:hover {
                opacity: 1;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: #eaeaea;
            }
        }
    }

    &__graph {
        width: 100%;
        height: 2px;
        display: flex;
        flex-direction: row;
        margin-top: calc($unit * 2);

        &__value {
            flex: 1 1 auto;
            background: $color-primary;
            height: 2px;
            margin-right: 3px;

            &.active {
                box-shadow: 0px 0px 18px 0px color($color-primary a(0.8));
            }
        }
    }

    &__metrics {
        lost-utility: clearfix;
        padding: calc($unit * 16) 0;

        @media (--screen-from-med) {
            padding: calc($unit * 30) 0;
        }

        @media (--screen-from-lrg) {
            padding: calc($unit * 60) 0;
        }

        &__heading {
            @media (--screen-from-lrg) {
                lost-column: 5/12;
                lost-offset: 1/12;
            }

            @media (--screen-from-xlrg) {
                lost-column: 4/12;
                lost-offset: 2/12;
            }
        }

        &__content {
            @media (--screen-from-lrg) {
                lost-column: 5/12;
            }

            @media (--screen-from-xlrg) {
                lost-column: 4/12;
            }
        }

        &__info {
            @media (--screen-to-lrg) {
                padding: calc($unit * 12) 0;
            }

            @media (--screen-from-lrg) {
                margin-top: calc($unit * 68);
                max-width: 240px;
            }
        }

        &__progress {
            width: 100%;
        }

        &__key {
            lost-utility: clearfix;
            margin-bottom: calc($unit * 12);

            &__item {
                lost-column: 1/2;
                margin-bottom: calc($unit * 16);

                h2 {
                    margin-top: calc($unit * 2);
                }
            }
        }
    }

    &__details {
        padding: calc($unit * 16) 0 calc($unit * 16) 0;

        @media (--screen-from-med) {
            padding: calc($unit * 20) 0 calc($unit * 20) 0;
        }

        @media (--screen-from-lrg) {
            padding: calc($unit * 62) 0 calc($unit * 32) 0;
        }

        &__intro {
            lost-utility: clearfix;
            padding: 0 0 calc($unit * 12) 0;

            @media (--screen-from-lrg) {
                padding: 0 0 calc($unit * 12) 0;
            }

            p {
                @media (--screen-from-lrg) {
                    lost-column: 4/12;
                    lost-offset: 6/12;
                }
            }
        }

        &__price,
        &__allocation,
        &__distribution {
            lost-utility: clearfix;
            padding: calc($unit * 10) 0 calc($unit * 10) 0;

            @media (--screen-from-med) {
                padding: calc($unit * 15) 0 calc($unit * 15) 0;
            }

            @media (--screen-from-lrg) {
                padding: calc($unit * 25) 0 calc($unit * 25) 0;
            }

            &__heading {
                @media (--screen-to-lrg) {
                    margin-bottom: calc($unit * 10);
                }
                @media (--screen-from-lrg) {
                    lost-column: 4/12;
                    lost-offset: 1/12;
                }
                @media (--screen-from-xlrg) {
                    lost-column: 3/12;
                    lost-offset: 2/12;
                }
            }

            &__content {
                @media (--screen-from-lrg) {
                    lost-column: 5/12;
                    lost-offset: 1/12;
                }
                @media (--screen-from-xlrg) {
                    lost-column: 4/12;
                    lost-offset: 1/12;
                }
            }
        }
    }
}
