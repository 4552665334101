/*------------------------------------*\
    # modules.modal
\*------------------------------------*/

.modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: $color-bg-alt;

    background-image: url(../../main/assets/images/background/background-grid-light-single.svg),
        url(../../main/assets/images/background/background-galaxy.png);
    background-repeat: no-repeat, no-repeat;
    background-position: center 50%;
    background-attachment: fixed;
    background-size: contain, auto;
    background-position: center center, 18vw -30%;

    &__wrapper {
        position: relative;
        width: 90%;
        padding-bottom: 56.25%;

        @media (--screen-to-lrg) {
            margin-top: calc($unit * 18);
            padding-left: 5%;
        }

        @media (--screen-from-lrg) {
            width: 100%;
            height: 100%;
            height: 0;
            padding-bottom: 56.25%; /* 16:9 */
        }
    }

    &__close {
        position: fixed;
        z-index: 10;
        top: calc($unit * 7);
        right: calc($unit * 14);
    }

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;

        @media (--screen-from-lrg) {
            width: 60%;
            height: 60%;

            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
