/*------------------------------------*\
    # utils.color
\*------------------------------------*/

.u-color {
    &--success {
        color: $color-success;
    }

    &--warning {
        color: $color-warning;
    }

    &--note {
        color: $color-note;
    }
}
