/*------------------------------------*\
    # plugin.progress-circle
\*------------------------------------*/

.progress-circle {
    position: relative; /* so that children can be absolutely positioned */
    padding: 0;
    width: 48px;
    height: 48px;
    background-color: color(white a(0.2)); 
    border-radius: 50%;
 }
 
 .progress-circle:after{
     border: none;
     position: absolute;
     top: 2px;
     left: 2px;
     text-align: center;
     display: block;
     border-radius: 50%;
     width: 44px;
     height: 44px;
     background-color: $color-bg-alt;
     content: " ";
 }
 .left-half-clipper { 
    /* a round circle */
    border-radius: 50%;
    width: 48px;
    height: 48px;
    position: absolute; /* needed for clipping */
    clip: rect(0, 48px, 48px, 24px); /* clips the whole left half*/ 
 }
 /* when p>50, don't clip left half*/
 .progress-circle.over50 .left-half-clipper {
    clip: rect(auto,auto,auto,auto);
 }
 .value-bar {
    /*This is an overlayed square, that is made round with the border radius,
    then it is cut to display only the left half, then rotated clockwise
    to escape the outer clipping path.*/ 
    position: absolute; /*needed for clipping*/
    clip: rect(0, 24px, 48px, 0);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 0.15em solid $color-primary; /*The border is 0.35 but making it larger removes visual artifacts */
    /*background-color: #4D642D;*/ /* for debug */
    box-sizing: border-box;
   
 }
 /* Progress bar filling the whole right half for values above 50% */
 .progress-circle.over50 .first50-bar {
    /*Progress bar for the first 50%, filling the whole right half*/
    position: absolute; /*needed for clipping*/
    clip: rect(0, 48px, 48px, 24px);
    background-color: $color-primary;
    border-radius: 50%;
    width: 48px;
    height: 48px;
 }
 .progress-circle:not(.over50) .first50-bar{ display: none; }
 
 /* Progress bar rotation position */
 .progress-circle .value-bar { transform: rotate(112deg); }