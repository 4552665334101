/*------------------------------------*\
    # views.home
\*------------------------------------*/

.home {
    &__section {
        &--hashnet {
            lost-utility: clearfix;

            @media (--screen-to-med) {
                padding-bottom: calc($unit * 12);
            }

            @media (--screen-from-med) {
                display: flex;
                align-items: center;
                justify-content: center;

                padding: 0 0 calc($unit * 62) 0;
            }

            &__video {
                border: 1px solid;
                border-color: color(white a(0.05));
                background: color($color-bg-alt a(0.7));
                transition: all 0.4s ease-in-out;
                position: relative;

                /*.light & {
                    border-color: color($color-bg-alt a(0.1));
                    background: color(white a(0.7));
                }

                .light & img {
                    filter: invert(1);
                }*/

                @media (--screen-from-med) {
                    lost-column: 10/12;
                    lost-move: 1/12;
                }

                @media (--screen-from-xlrg) {
                    lost-column: 6/12;
                    lost-move: 1/12;
                }

                &__label {
                    position: absolute;
                    top: calc($unit * 8);
                    left: calc($unit * 8);
                }
            }

            &__heading {
                margin-top: calc(-$unit * 24);
                position: relative;

                @media (--screen-from-sml) and (--screen-to-med) {
                    lost-column: 10/12;
                    lost-move: 1/12;
                }

                @media (--screen-from-med) {
                    lost-column: 10/12;
                    lost-move: 2/12;
                }

                @media (--screen-from-xlrg) {
                    lost-column: 5/12;
                    lost-move: 0/12;
                    margin-top: calc($unit * 20);
                }
            }
        }

        &--features {
            padding: calc($unit * 12) 0 calc($unit * 12) 0;

            @media (--screen-from-med) {
                padding: calc($unit * 62) 0 calc($unit * 32) 0;
            }
        }

        &--comparison {
            padding: calc($unit * 12) 0 calc($unit * 12) 0;

            @media (--screen-from-med) {
                padding: calc($unit * 62) 0 calc($unit * 62) 0;
            }

            &__table {
                overflow: auto;

                table {
                    white-space: nowrap;
                }
            }
        }

        &--roadmap {
            padding: 0 0 calc($unit * 12) 0;

            @media (--screen-from-med) {
                padding: 0 0 calc($unit * 62) 0;
            }

            &__action {
                text-align: center;
            }
            &__map {
                margin: calc($unit * 12) 0 calc($unit * 6) 0;

                @media (--screen-from-med) {
                    margin: calc($unit * 24) 0 calc($unit * 12) 0;
                }
            }
        }

        &--tokensale {
            padding: calc($unit * 12) 0 calc($unit * 12) 0;

            @media (--screen-from-med) {
                padding: calc($unit * 62) 0 calc($unit * 62) 0;
            }

            &__header {
                margin-bottom: calc($unit * 10);

                @media (--screen-from-lrg) {
                    lost-offset: 2/12;
                    margin-bottom: calc($unit * 30);
                }
            }

            &__content {
                @media (--screen-from-lrg) {
                    lost-utility: clearfix;
                }
            }

            &__item {
                @media (--screen-to-lrg) {
                    margin-bottom: calc($unit * 10);
                }
                @media (--screen-from-med) {
                    margin-bottom: calc($unit * 10);
                }
                @media (--screen-from-lrg) {
                    lost-column: 3/12;
                    lost-offset: 1/12;
                }
            }
        }

        &--news {
            padding: 0 0 calc($unit * 8) 0;

            @media (--screen-from-med) {
                padding: 0 0 calc($unit * 32) 0;
            }
        }

        &--partners {
            padding: calc($unit * 12) 0 calc($unit * 12) 0;

            @media (--screen-from-med) {
                padding: calc($unit * 62) 0 calc($unit * 62) 0;
            }

            &__header {
                margin-bottom: calc($unit * 10);

                @media (--screen-from-lrg) {
                    lost-offset: 2/12;
                    margin-bottom: calc($unit * 30);
                }
            }

            &__content {
            }
        }
    }
}
