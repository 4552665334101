/*------------------------------------*\
    # modules.container
\*------------------------------------*/

.container {
    background: $color-bg-alt;
    color: white;
    /*position: relative;*/

    /*&__lightoverlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        background: white;
        visibility: hidden;

        .light & {
            visibility: visible;
        }
    }*/

    /*&.light {        
        color: $color-text;

        .u-type--light-color {
            color: color($color-text-light blackness(+60%));
        }
    }*/

    &__content {
        margin: 0 auto;
        /*position: relative;*/

        /* Animation defaults */
        /* opacity: 0; */
    }

    &__children {
    }

    &__overlay {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-bg-alt;
    }
}