/*------------------------------------*\
    # components.nav
\*------------------------------------*/

.nav {
    display: flex;

    padding: 0 24px;
    margin: 0 auto;
    font-family: $ff-sans-compressed;

    &.is-open {
        @media (--screen-to-xlrg) {
            background: $color-bg-alt;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
    }

    @media (--screen-from-med) {
        padding: 0 60px;
    }

    &__branding {
        padding-top: calc($unit * 4);

        @media (--screen-from-xlrg) {
            padding-top: calc($unit * 8);
        }

        a {
            opacity: 0.6;
            transition: opacity 0.3s ease-in-out;

            &:hover,
            &:focus {
                opacity: 1;
            }
        }
    }

    &__menu-toggle {
        display: block;
        padding: 36px 12px;

        @mixin type-scale $type-tiny, 4;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-family: $ff-sans-compressed;

        @media (--screen-from-xlrg) {
            padding: 36px 20px;
        }
    }

    &__links {
        @media (--screen-to-xlrg) {
            /*display: none;*/

            position: fixed;
            top: 65px;
            left: 0;
            width: 100%;
            height: calc(100vh - 65px);
            overflow: auto;
            background: $color-bg-alt;
            z-index: 5;
            padding-left: calc($unit * 14);

            /*transform: translateY(-100vh);*/
            opacity: 0;
            transition: opacity 0.4s;

            pointer-events: none;

            &.is-open {
                display: block;
                transform: translateY(0);
                opacity: 1;
                pointer-events: auto;
            }
        }

        @media (--screen-to-med) {
            padding-left: calc($unit * 6);
        }

        @media (--screen-from-lrg) {
            display: flex;
        }

        @media (--screen-from-xlrg) {
            padding-left: calc($unit * 12);
        }

        &__item {
            flex: 1;
            position: relative;

            &--dropdown {
                &:hover,
                &:focus-within {
                    ul {
                        @media (--screen-from-xlrg) {
                            transform: translateY(0);
                            opacity: 1 !important;
                            visibility: visible !important;
                        }
                    }
                }

                &:after {
                    @media (--screen-from-xlrg) {
                        z-index: 1;
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0;
                        left: 4px;
                        top: 50%;
                        transform: translateY(-50%);
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;

                        border-top: 4px solid $color-primary;
                    }
                }

                .nav__links__item--dropdown {
                    &:after {
                        display: none;
                    }
                }

                .nav__sublinks.is-open {
                    @media (--screen-to-xlrg) {
                        opacity: 1 !important;
                        visibility: visible !important;
                    }
                }
            }

            &--dropside {
                ul {
                    @media (--screen-to-xlrg) {
                        padding-left: calc($unit * 8);
                    }
                }

                &:hover,
                &:focus-within {
                    ul {
                        display: block;

                        @media (--screen-from-xlrg) {
                            transform: translateY(0);
                            opacity: 1 !important;
                            visibility: visible !important;
                        }
                    }
                }
            }
        }

        &__container {
            margin-top: calc($unit * 8);

            @media (--screen-from-xlrg) {
                display: none;
            }

            @media (--screen-to-xlrg) {
                padding-right: calc($unit * 20);
            }

            @media (--screen-to-med) {
                padding-right: calc($unit * 10);
            }
        }
    }

    &__right {
        display: flex;
        margin-left: auto;

        .btn {
            margin-top: calc($unit * 7);

            @media (--screen-to-xlrg) {
                display: none;
            }
        }
    }

    &__sublinks {
        transition: all 0.2s ease-out;

        @media (--screen-to-xlrg) {
            &.is-open {
                display: block;
            }
        }

        @media (--screen-from-xlrg) {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 2;

            min-width: 260px;
            border: 1px solid color($color-primary a(0.1));
            background: color($color-bg-alt a(0.85));
            transform: translateY(-12px);

            opacity: 0.7;
            visibility: hidden;
        }

        &--aside {
            top: -1px;
            border-bottom: 0px;
            left: 100%;

            @media (--screen-from-xlrg) {
                display: none;
            }
        }

        a {
            display: block;

            @mixin type-scale $type-tiny, 4;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            font-family: $ff-sans-compressed;
            border-bottom: 1px solid color($color-primary a(0.1));

            @media (--screen-to-lrg) {
                padding: calc($unit * 5) 0;
                margin-left: calc($unit * 8);
            }

            @media (--screen-from-xlrg) {
                padding: calc($unit * 6) calc($unit * 6) calc($unit * 6)
                    calc($unit * 8);
            }
        }
    }

    &__link {
        position: relative;
        display: block;
        padding: 44px 20px;

        @media (--screen-to-xlrg) {
            padding: 24px 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }

        @mixin type-scale $type-tiny, 4;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        font-family: $ff-sans-compressed;

        @media (--screen-from-xlrg) {
            &:hover {
                color: $color-primary;

                &:after {
                    width: 10px;
                }
            }
        }

        &.active {
            color: color($color-primary lightness(+30%));
        }

        &__expand {
            display: inline-block;
            position: relative;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 10px;
            transition: all 0.2s ease-in-out;

            @media (--screen-to-lrg) {
                right: calc($unit * 20);
            }

            @media (--screen-to-med) {
                right: calc($unit * 10);
            }

            @media (--screen-from-lrg) {
            }
            display: none;

            &.is-open {
                transform: rotate(225deg);
            }

            &:before {
                content: '';

                position: absolute;
                z-index: 1;
                top: 50%;
                right: 0;

                width: 24px;
                height: 1px;
                background-color: $color-primary;
            }
            &:after {
                content: '';

                position: absolute;
                z-index: 1;
                top: 0;
                right: 11px;
                width: 1px;
                height: 24px;
                background-color: $color-primary;
            }
        }

        &--toggle-menu {
            padding: 24px 0 24px 12px;

            color: $color-primary;
            text-decoration: none;
            border: none;
            background: transparent;

            &:hover {
                opacity: 0.75;
            }
            &:focus {
                opacity: 0.75;
                outline: none;
            }

            @media (--screen-from-xlrg) {
                display: none;
            }
        }

        &__dropdown-icon {
            display: flex;
            align-items: center;
            margin-right: calc($unit * 2);
            width: 4px;
        }
    }
}
