/*------------------------------------*\
    # views.taquin
\*------------------------------------*/

.taquin {
    padding: calc($unit * 20) 0;

    @media (--screen-from-med) {
        padding: calc($unit * 40) 0;
    }
}

.taquin__body {
    padding-top: calc($unit * 8);

    @media (--screen-from-lrg) {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
    }
}

.taquin__steps {
    grid-column: 1/4;
}

.taquin__download {
    grid-column: 6/9;
}
.taquin__step {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: calc($unit * 8);

    svg {
        min-width: 80px;
        margin-right: calc($unit * 6);
    }
}
