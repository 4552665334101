/*------------------------------------*\
    # modules.field
\*------------------------------------*/

.field {

    /**
     * Field base
     */
    margin-bottom: calc($unit * 4);

    &__lbl {
        display: block;
        margin-bottom: calc($unit);
        @mixin type-scale $type-small, 0.75;
        color: color($color-text a(0.85));

        &--nextToInput {
            padding-top: calc($unit * 3);
        }
    }

    /* Field group holds input and buttons together */
    &__group {
        display: table;

        & > * {
            display: table-cell;
            vertical-align: top;
        }

    }

    /* Icon on the left or right of the input */
    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;

        &--left {
            left: 0;
        }

        &--right {
            right: 0;
        }

    }
    
}