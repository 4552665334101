/*------------------------------------*\
    # components.nesletter
\*------------------------------------*/

.newsletter {
    padding: calc($unit * 16) 0 calc($unit * 28) 0;
    border-top: 1px solid rgba(216, 216, 216, 0.1);

    background-color: $color-bg-alt;
    background-image: url(../../main/assets/images/background/pattern.jpg);
    background-repeat: repeat-x;
    background-position: center bottom;

    position: relative;
    z-index: 2;

    @media (--screen-from-med) {
        padding: calc($unit * 57) 0 calc($unit * 65) 0;
    }

    &__content {
        text-align: center;
        color: white;
        padding-top: calc($unit * 10);

        @media (--screen-from-med) {
            lost-center: 8/12;
            padding-top: calc($unit * 30);
        }

        @media (--screen-from-xlrg) {
            lost-center: 1/2;
        }
    }

    &__title {
        margin-bottom: calc($unit * 4);

        @media (--screen-from-med) {
            margin-bottom: calc($unit * 8);
        }
    }

    &__claim {
        margin-bottom: calc($unit * 10);
    }

    &__form {
        @media (--screen-from-med) {
            display: flex;
            justify-content: space-between;

            .btn {
                margin-left: calc($unit * 2);
            }
        }

        .btn {
            @media (--screen-to-med) {
                width: 100%;
                margin-top: calc($unit * 4);
            }
        }
    }
}
