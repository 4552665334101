/*------------------------------------*\
    # utils.well
\*------------------------------------*/

.u-well {

    &--bordered {
        padding-bottom: calc($unit * 4);
        border-bottom: 1px solid color(white blackness(+15%));
    }

}