/*------------------------------------*\
    # components.news
\*------------------------------------*/

.news {
    padding-bottom: calc($unit * 20);

    @media (--screen-from-lrg) {
        padding-bottom: calc($unit * 60);
    }

    &__header {
        margin-bottom: calc($unit * 16);

        @media (--screen-from-lrg) {
            lost-offset: 2/12;
            lost-column: 8/12;
            margin-bottom: calc($unit * 25);
        }

        &__wrapper {
            display: table;
            position: relative;
            border-collapse: separate;
            width: 100%;
        }

        &__title {
            display: table-cell;
            width: 100%;
            float: left;
            z-index: 2;
            position: relative;
        }

        &__link {
            display: table-cell;
            width: 1%;
            white-space: nowrap;
            vertical-align: middle;
            position: relative;
        }
    }

    &__content {
        @media (--screen-from-xlrg) {
            lost-offset: 2/12;
        }
    }

    &__article {
        @mixin clearfix;
        margin-bottom: calc($unit * 20);

        @media (--screen-from-med) {
            margin-bottom: calc($unit * 30);
        }

        &__thumbnail {
            position: relative;
            padding: calc($unit * 2);
            min-height: 240px;

            border: 1px solid color($color-tertiary a(0.4));

            @media (--screen-to-med) {
                margin-bottom: calc($unit * 8);
            }

            @media (--screen-from-med) {
                lost-column: 6/12;
            }

            @media (--screen-from-xlrg) {
                lost-column: 4/12;
            }

            &:before {
                content: '';
                position: absolute;
                top: calc($unit * (-2));
                left: calc($unit * (-2));
                width: calc($unit * 11);
                height: calc($unit * 8);
                border-top: 1px solid color($color-primary a(0.8));
                border-left: 1px solid color($color-primary a(0.8));
            }

            &__image {
                width: 100%;
                min-height: 224px;
                background-size: cover;
                background-position: center;
            }

            &__item {
                width: 100%;
                background: salmon;
            }
        }

        &__preview {
            @media (--screen-from-med) {
                lost-column: 6/12;
                margin-top: calc($unit * 2);
                padding-left: calc($unit * 12);
            }

            @media (--screen-from-xlrg) {
                lost-column: 5/12;
            }

            &__label {
                @mixin type-scale $type-small, 0.75;

                @media (--screen-from-lrg) {
                    margin-bottom: $unit;
                }
            }

            &__title {
                margin-bottom: calc($unit * 3);

                @media (--screen-from-lrg) {
                    margin-bottom: calc($unit * 10);
                }
            }

            &__summary {
                margin-bottom: calc($unit * 4);

                @media (--screen-from-lrg) {
                    margin-bottom: calc($unit * 5);
                }
            }
        }
    }
}
