/*------------------------------------*\
    # components.partners
\*------------------------------------*/

.partners {

    &__list {
        lost-flex-container: row;

        &__item {
            lost-column: 1/2 flex;
            padding: calc($unit * 4);

            @media (--screen-from-med) {
                lost-column: 1/3 flex;
                padding: calc($unit * 12);
            }

            @media (--screen-from-lrg) {
                lost-column: 1/4 flex;
            }
        }
    }
}