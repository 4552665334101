/*------------------------------------*\
    # utils.radius
\*------------------------------------*/

.u {

    &-radial {
        border-radius: $global-radial;
    }

    &-rounded {
        border-radius: $global-rounded;
    }
    
}