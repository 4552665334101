/*------------------------------------*\
    # components.social-bar
\*------------------------------------*/

.social-bar {
    display: block;
    position: relative;

    &__wrapper {
        @media (--screen-from-med) {
            position: absolute;
            z-index: 3;
            padding-left: calc($unit * 8);
            height: 100vh;
        }

        @media (--screen-from-xlrg) {
            padding: calc($unit * 15);
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        vertical-align: middle;
        fill: white;
        transition: all 0.15s ease-in-out;
    }

    &__item {
        position: relative;
        z-index: 5;
        text-align: center;
        border: 1px solid #3f414c;
        background: color($color-bg-alt a(0.8));
        transition: all 0.15s ease-in-out;
        height: 48px;
        width: 48px;

        @media (--screen-from-lrg) {
            height: 60px;
            width: 60px;
        }

        &__label {
            display: block;
            position: absolute;
            top: -1px;
            left: 48px;
            width: 0;
            height: 50px;

            padding: calc($unit * 4) 0;

            border: 1px solid #3f414c;
            background: $color-bg-alt;

            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            transition: all 0.15s ease-in-out;

            @mixin type-scale $type-tiny, 4;
            letter-spacing: .2em;

            @media (--screen-from-lrg) {
                height: 60px;
                left: 58px;
                padding: calc($unit * 5) 0;
            }
        }

        &:hover {
            .social-bar__icon {
                fill: $color-primary;
            }

            .social-bar__item__label {
                padding-left: calc($unit * 6);
                padding-right: calc($unit * 6);
                width: auto;
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__connect {
        display: block;
        margin-top: calc($unit * 8);
        margin-bottom: calc($unit * 2);
    }

    &--vertical {
        top: 50%;
        transform: translateY(-50%);
        width: 60px;

        @media (--screen-to-lrg) {
            display: none;
        }

        .social-bar__item {
            display: block;
            margin-top: -1px;
        }

        .social-bar__description {
            transform: translateY(160px) rotate(-90deg);
            white-space: nowrap;

            color: color(white a(0.2));
            
            @mixin type-scale $type-nano, 4;
            font-family: $ff-sans-wide;
            text-transform: uppercase;
            letter-spacing: .3em;
        }
    }

    &--horizontal {
        .social-bar__item {
            display: inline-block;
            margin-left: -1px;
            padding: calc($unit * 3.25);
            width: 48px;

            @media (--screen-from-lrg) {
                width: 60px;
            }


        }
    }

    &--inmenu {
        .social-bar__icon {
            display: block;
            position: relative;
        }
        .social-bar__item {
            display: block;

            &:hover {
                .social-bar__item__label {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        .social-bar__item__label {
            position: relative;
            visibility: visible;
            opacity: 1;
            width: min-content;
            left: 70px;
            border: none;
            padding: calc($unit * 2) 0;
            color: white;
            overflow: visible;
        }
    }
}
