/*------------------------------------*\
    # components.hero
\*------------------------------------*/

.hero {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc($unit * 30) 0;
    
    &__content {
        text-align: center;
        color: white;

        @media (--screen-from-med) {
            lost-center: 1/1;
        }
    }

    &__title {
        @media (--screen-to-med) {
            margin-bottom: calc($unit * 6);
            line-height: 16px;
        }

        @media (--screen-from-med) {
            margin-bottom: calc($unit * 7);
        }
    }

    &__subtitle {
        @mixin type-scale $type-small, 6;
    }

    &__maintitle {
       @mixin type-scale $type-medium, 9;

        @media (--screen-from-med) {
            @mixin type-scale $type-xxlarge, 16;
        }
        @media (--screen-from-lrg) {
            @mixin type-scale $type-huge, 19;
        }   
    }

    &__claim {
        max-width: 24em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: calc($unit * 8);

        @media (--screen-from-med) {
            margin-bottom: calc($unit * 14);
            max-width: 26em;
        }

        @media (--screen-from-lrg) {
            max-width: 42em;
        }
    }
}
