/*------------------------------------*\
    # utils.visibility
\*------------------------------------*/

.is {

    &-hidden {
        display: none;
    }

    &-visible {
        display: block;
    }
        
}