/*------------------------------------*\
    # utils.bg
\*------------------------------------*/

.u-bg {
    &--primary {
        background-color: $color-primary;
    }

    &--dark {
        background-color: $color-bg-alt;
    }

    &--light {
        background-color: $color-bg;
    }
    
    &--video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        z-index: 1;
        opacity: 1;
        /*transform: translateX(-50%); */
        transition: filter .4s ease-in-out;
        background: $color-bg-alt;

        @media (--screen-to-med) {
            display: none;
        }

        &.away-from-top {
            opacity: 0.5;
        }

        /*.light & {
            filter: invert(1) grayscale(1) contrast(1.4);
        }*/

        video {
            margin-top: 6vh;
            height: 95vh;
            opacity: 0.25;
            max-width: 100%;

            @media (--screen-to-med) {
                /*width: 200%;
                max-width: 200%;*/
                display: none;
            }
        }
    }
    
    &--space {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 2;
        opacity: 1;

        transition: all .4s ease-in-out;

        background-image: url(../../main/assets/images/background/background-galaxy.png);
        background-repeat: no-repeat;
        background-position: 18vw -30%;

        /*.light & {
            filter: invert(1) grayscale(1) contrast(1.4);
        }*/
    }

    &--deco {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        z-index: 2;
        opacity: 1;

        @media (--screen-to-med) {
            width: 200%;
            left: -50%;

            display: none;
        }

        background-image: url(../../main/assets/images/background/background-grid-light-single.svg);
        background-repeat: no-repeat;
        background-position: center 50%;
        background-attachment: fixed;
        background-size: contain;

        /*.light & {
            background-image: url(../../main/assets/images/background/background-grid-dark-single.svg);
            background-repeat: no-repeat;
            background-position: center 50%;
            background-attachment: fixed;
            background-size: contain;
        }*/

        &.away-from-top {
            img {
                opacity: 0;
            }
        }

        img {
            max-height: 76vh;
            margin: 14vh 14vw 14vh 14vw;
            transition: opacity .4s ease-in-out;
            margin-top: 52vh;
            transform: translateY(-50%);
        }
    }

    &--top {
        position: relative;
        z-index: 3;
    }
}
