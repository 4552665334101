/*------------------------------------*\
    # utils.width
\*------------------------------------*/

.u-width {
    
    &--full {
        width: 100%;
    }
    
}