.staking__header__button {
    opacity: 0;
    transform: translateY(20px);
}

.staking__section {
    padding: calc($unit * 24) 0;

    @media (--screen-from-xxlrg) {
        padding: calc($unit * 48) 0;
    }
}

.staking__sticky-icon {
    position: sticky;
    top: calc($unit * 25);
    display: none;

    @media (--screen-from-xlrg) {
        display: block;
    }
}

.staking__wrapper {
    position: relative;
    z-index: 2;
    padding: 0 calc($unit * 6);

    @media (--screen-from-med) {
        padding: 0 calc($unit * 7);
    }

    @media (--screen-from-xxlrg) {
        max-width: 1692px;
        padding: 0 calc($unit * 12);
    }
}

.staking__col {
    @media (--screen-from-lrg) {
        padding-right: calc($unit * 8);
    }
}

.staking__section--top {
    padding-top: calc($unit * 24);

    @media (--screen-from-xxlrg) {
        padding-top: calc($unit * 48);
    }
}

.staking__section--bottom {
    padding-bottom: calc($unit * 24);

    @media (--screen-from-xlrg) {
        padding-bottom: calc($unit * 48);
    }
}

.staking__title {
    padding-bottom: calc($unit * 10);

    @media (--screen-from-xlrg) {
        padding-bottom: calc($unit * 20);
    }
}

.staking__title--large {
    padding-bottom: calc($unit * 15);

    @media (--screen-from-xlrg) {
        padding-bottom: calc($unit * 30);
    }
}

.staking__question {
    padding-bottom: calc($unit * 7);
}

.staking__answer {
    padding-bottom: calc($unit * 12);

    @media (--screen-from-xlrg) {
        padding-bottom: calc($unit * 25);
    }
}

.stake-rewards__item:not(:last-child) {
    padding-bottom: calc($unit * 6);

    @media (--screen-from-med) {
        padding-bottom: calc($unit * 17);
    }
}

.stake-rewards__column {
    padding-bottom: calc($unit * 12);

    @media (--screen-from-med) {
        padding-bottom: 0;
    }
}

.staking__rewards-col {
    margin-bottom: calc($unit * 12);
    @media (--screen-from-lrg) {
        margin-bottom: 0;
        padding-right: calc($unit * 40);
    }
}

.staking__rewards-row {
    margin-bottom: calc($unit * 4);
    @media (--screen-from-lrg) {
        margin-bottom: 0;
        padding-bottom: calc($unit * 110);
    }
}

.staking__rewards-info {
    @media (--screen-from-lrg) {
        padding-right: 10%;
    }
}

.staking__join-col {
    margin-bottom: calc($unit * 8);

    @media (--screen-from-lrg) {
        margin-bottom: 0;
        padding-right: 10%;
    }
}

.staking__join-token {
    word-break: break-all;
}

.staking__join-title {
    position: relative;
    margin-bottom: calc($unit * 8);

    &:before {
        content: '';
        position: absolute;
        transform: translateX(-100%);
        top: calc($unit * 2);
        left: calc($unit * -5);
        width: 44px;
        height: 44px;

        background: url(../../main/assets/images/staking/icons/money-time.svg)
            center;
        display: none;

        @media (--screen-from-xlrg) {
            display: block;
        }
    }
}

.staking__faq {
    position: relative;
    overflow: hidden;

    &:after {
        content: '';
        width: 906px;
        height: 825px;
        bottom: -130px;
        left: 0;
        right: 0;
        margin: 0 auto;
        position: absolute;
        background-image: url(../../main/assets/images/staking/icons/globe-dot.svg);
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 1;
    }
}

.staking__join {
    a {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
