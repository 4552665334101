/*------------------------------------*\
    # components.comparison
\*------------------------------------*/

.comparison {
    position: relative;
    z-index: 6;
    padding: calc($unit * 65) 0;

    &__header {
        margin-bottom: calc($unit * 11);

        @media (--screen-from-med) {
            margin-bottom: calc($unit * 33);
        }
    }

    &__table {
        width: 100%;
        min-width: 1024px;
        border-collapse: collapse;
        background: $color-bg-alt;

        tr {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }

        td,
        th {
            text-align: center;
        }

        th {
            padding: calc($unit * 5) 0 calc($unit * 3) 0;
        }

        td {
            padding: calc($unit * 4) 0;
            @mixin type-scale $type-small, 4;

            @media (--screen-from-med) {
                padding: calc($unit * 8) 0;
            }

            @media (--screen-from-xlrg) {
                padding: calc($unit * 14) 0;
            }
        }

        th, td {
            &:nth-child(2) {
                background: url(../../main/assets/images/background/dots-table.png) center;
                padding-left: calc($unit * 6);
                padding-right: calc($unit * 6);
            }
        }

        th, td {
            &:nth-child(10), &:nth-child(9) {
                @media (--screen-to-xxlrg) {
                    display: none;
                }
            }
        }

        &__title {
            @mixin type-scale $type-tiny, 4;
            color: color(#BEF1FA a(0.7));
            font-family: $ff-sans-wide;
            font-weight: $type-weight-thin;

            @media (--screen-from-med) {
                @mixin type-scale $type-small, 5;
            }
        }

        &__feature {
            @mixin type-scale $type-tiny, 4;
            color: color(#BEF1FA a(0.7));
            font-family: $ff-sans-wide;
            padding-left: calc($unit * 8) !important;
            
            text-align: left !important;
            max-width: 200px;
            
            @media (--screen-to-med) {
                max-width: 120px;
            }

            @media (--screen-from-med) {
                padding-left: calc($unit * 14) !important;
            }
            
            @media (--screen-from-xlrg) {
                @mixin type-scale $type-small, 5;
            }
        }
    }

    &__icon {

        &--check {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url(../../main/assets/icons/icon-check.svg) center no-repeat;
        }

        &--x {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url(../../main/assets/icons/icon-close.svg) center no-repeat;
        }

        &--energy {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url(../../main/assets/icons/icon-thunder.svg) center no-repeat;
        }
    }
}
