/*------------------------------------*\
    # modules.formatted
\*------------------------------------*/

.formatted {
    p,
    ul,
    ol,
    dl,
    table,
    object,
    img {
        margin-bottom: calc($unit * 4);
    }

    figure {
        margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 900;
        margin-top: calc($unit * 20);
        margin-bottom: calc($unit * 10);
        font-weight: $type-weight-ultra;
        font-family: $ff-sans-wide;
        text-transform: uppercase;
    }

    h1 {
        @mixin type-scale $type-xxxlarge, 19;
    }
    h2 {
        @mixin type-scale $type-xxlarge, 14;
    }
    h3 {
        @mixin type-scale $type-large, 12;
    }
    h4,
    h5,
    h6 {
        @mixin type-scale $type-large, 10;
    }

    ul,
    ol,
    dl {
        list-style: inherit;
        list-style-position: inside;
    }

    p {
        @mixin type-scale $type-small, 5;
        @media (--screen-from-med) {
            @mixin type-scale $type-base, 7;
        }
        font-family: $ff-sans;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    pre {
        padding: calc($unit * 4) calc($unit * 6);
        background: rgba(0, 0, 0, 0.3);
        margin: calc($unit * 6) 0;
        white-space: break-spaces;
        word-break: break-word;
    }
}
