/*------------------------------------*\
    # views.roadmap
\*------------------------------------*/

.roadmap {
    &__content {
        padding: 0 calc($unit * 12) calc($unit * 25) calc($unit * 12);

        @media (--screen-from-med) {
            padding: 0 calc($unit * 12) calc($unit * 60) calc($unit * 12);
        }
    }

    &__map {}

}