/*------------------------------------*\
    # components.person
\*------------------------------------*/

.person {

    &__wrapper {
        
        @media (--screen-from-lrg) {
            lost-flex-container: row;
            border-top: 1px solid color(white a(0.1));
        }
        
        .section--light & {
            @media (--screen-from-lrg) {
                border-top: 1px solid color(black a(0.1));
            }
        }
    }

    position: relative;
    overflow: hidden;

    /* .light & {
        background: color(white a(0.5));
    }
    
    background: color($color-bg-alt a(0.5)); */

    padding-bottom: calc($unit * 14);

    .section--light & {
        @media (--screen-from-lrg) {
            border-bottom: 1px solid color(black a(0.1));
            border-left: 1px solid color(black a(0.1));
            border-right: 1px solid color(black a(0.1));
        }
    }    
    
    @media (--screen-from-lrg) {
        lost-column: 1/2 0 flex;
        padding: calc($unit * 16) calc($unit * 12);
        border-bottom: 1px solid color(white a(0.1));
        border-left: 1px solid color(white a(0.1));
        border-right: 1px solid color(white a(0.1));
    }

    &:nth-child(2n) {
        border-left: 0;
    }

    &:hover {
        .person__photo img {
            filter: grayscale(0);
        }

        .person__photo:after {
            transform: rotate(135deg);
            border-top: 1px solid color($color-primary a(1));
            border-right: 1px solid transparent;
            border-bottom: 1px solid color($color-primary a(1));
            border-left: 1px solid color($color-primary a(1));
        }
    }

    &__more {
        margin-top: calc($unit * 2);
    }

    &__photo {
        position: relative;
        width: 80px;
        height: 80px;
        padding: 10px 0 0 10px;

        @media (--screen-to-sml) {
            margin-bottom: calc($unit * 4);
        }

        @media (--screen-from-sml) {
            float: left;
        }

        &:after {
            content: '';
            display: block;

            width: 80px;
            height: 80px;

            position: absolute;
            top: 0;
            left: 0;

            transform: rotate(45deg);
            transition: all .4s ease-out;

            border-top: 1px solid color($color-primary a(0.5));
            border-right: 1px solid transparent;
            border-bottom: 1px solid color($color-primary a(0.5));
            border-left: 1px solid color($color-primary a(0.5));
            border-radius: 50%;
        }

        img {
            width: 60px;
            border-radius: 50%;
            filter: grayscale(100%);
            transition: all .4s ease-out;
        }
    }

    &__content {
        @media (--screen-from-sml) {
            padding-left: 100px;
        }
    }

    &__description {
        padding-top: calc($unit * 3);
    }

    &__linkedin {
        position: absolute;
        top: 0;
        right: 0;
        height: 36px;
        width: 36px;
        padding: calc($unit * 4);
        border: 1px solid $color-primary;
        transition: all 0.2s;

        &:hover {
            background: $color-primary;

            svg {
                fill: white;
            }
        }

        svg {
            fill: $color-primary;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
