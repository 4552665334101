/*------------------------------------*\
    # base.globals
\*------------------------------------*/

/**
 * Initialize typomatic in project (sets font-size and line-height
 * on html selector.
 */
html {
    @mixin typomatic-init;
}

body {
    position: relative;
    font-family: $ff-sans-condensed;
    font-weight: $type-weight-light;
    color: $color-text;
    background: $color-bg;
    -webkit-font-smoothing: antialiased;

    /* Lock scrolling on mobile nav open */
    &.is-locked {
        @media (--screen-to-lrg) {
            overflow: hidden;
        }
    }
}

img {
    max-width: 100%;
    vertical-align: middle;
}

svg {
    max-width: 100%;
}

section[data-scrollbar] {
    height: 100vh;
}
