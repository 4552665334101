/*------------------------------------*\
    # components.contentpage
\*------------------------------------*/

.contentpage {
    padding-top: calc($unit * 20);

    @media (--screen-from-med) {
        padding-top: calc($unit * 40);
    }

    &__header {
        lost-utility: clearfix;
        /*overflow: hidden;*/
        padding-bottom: calc($unit * 20);

        /*@media (--screen-from-med) {*/
        min-height: 50vh;

        @media (--screen-from-med) {
            min-height: 100vh;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        /*}*/

        &__toc {
            display: inline-block;
            margin-left: 50%;
            transform: translateX(-50%);
            margin-top: calc($unit * 25);

            li {
                text-align: left;

                a {
                    position: relative;
                    display: block;
                    padding: calc($unit * 4) 0;

                    white-space: nowrap;

                    &:before {
                        content: '';
                        display: block;
                        width: 0;

                        position: absolute;
                        top: 52%;
                        left: calc($unit * 6);

                        height: 1px;
                        background: $color-warning;

                        transition: all 0.1s ease-out;
                    }

                    &:hover,
                    &:focus {
                        .contentpage__header__toc__title {
                            transform: translateX(40px);
                        }

                        &:before {
                            width: calc($unit * 10);
                        }
                    }
                }
            }

            &__number {
                @mixin type-scale $type-micro, 4;
                color: $color-warning;
                font-weight: $type-weight-bold;
            }

            &__title {
                display: inline-block;
                padding-left: calc($unit * 4);
                transition: all 0.1s ease-out;

                @mixin type-scale $type-small, 5;
                @media (--screen-from-med) {
                    @mixin type-scale $type-base, 7;
                }

                font-family: $ff-sans-wide;
                color: white;
            }
        }
    }

    &__title {
        text-align: center;

        /* Animation defaults */
        transform: translate3d(0, 0, 0);
        opacity: 0;

        &--left-aligned {
            @media (--screen-from-lrg) {
                text-align: left;
            }
        }
    }

    &__subtitle {
        text-align: center;

        @media (--screen-from-med) {
            lost-center: 7/12;
        }

        @media (--screen-from-lrg) {
            lost-center: 10/12;
        }

        @media (--screen-from-xlrg) {
            lost-center: 10/12;
        }
    }
}
