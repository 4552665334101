/*------------------------------------*\
    # modules.btn
\*------------------------------------*/

.btn {
    /**
     * Button base
     */
    display: inline-block;
    vertical-align: middle;

    touch-action: manipulation;
    user-select: none;

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    border: 0;
    background: transparent;

    transition: all 0.15s ease-out;

    &:focus {
        outline: 0;
    }

    /**
     * Button base project styling
     */
    font-family: $ff-sans-compressed;
    font-weight: $type-weight-regular;
    text-transform: uppercase;
    letter-spacing: 0.15em;

    /**
     * Button styles
     */
    &--primary {
        position: relative;
        border: 1px solid color($color-primary a(0.2));
        color: $color-primary;

        &:after,
        &:before {
            content: '';
            display: block;

            position: absolute;
            top: 0;
            left: 0;

            transition: all 0.3s ease-out;
        }

        &:after {
            width: 0;
            height: 100%;

            border-top: 1px solid $color-primary;
            border-bottom: 1px solid $color-primary;
        }

        &:before {
            width: 100%;
            height: 0;

            border-right: 1px solid $color-primary;
            border-left: 1px solid $color-primary;
        }

        &:hover,
        &:focus {
            background-color: transparent;
            color: $color-primary;

            &:after {
                width: 100%;
            }

            &:before {
                height: 100%;
            }
        }

        &:disabled,
        &.is-disabled {
            &:hover,
            &:focus {
                background-color: transparent;
                color: $color-primary;
            }
        }
    }

    &--secondary {
        border: 1px solid $color-secondary;
        color: $color-secondary;
        &:hover,
        &:focus {
            background: $color-secondary;
            color: white;
        }

        &:disabled,
        &.is-disabled {
            &:hover,
            &:focus {
                background-color: transparent;
                color: $color-secondary;
            }
        }
    }

    &--ghost {
        background-color: transparent;
        color: $color-primary;
        &:hover,
        &:focus {
            color: color($color-primary blackness(+10%));
        }

        &:disabled,
        &.is-disabled {
            &:hover,
            &:focus {
                background-color: transparent;
                color: $color-primary;
            }
        }
    }

    /**
     * Disabled
     */

    &.is-disabled,
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
        background-color: transparent;
    }

    &.is-disabled {
        pointer-events: none;
    }

    /**
     * Button sizes
     */
    &--sml {
        @mixin type-scale $type-tiny, 4;
        height: calc($unit * 9);
        line-height: calc($unit * 9);
        padding: 0 calc($unit * 5);
    }

    &--med {
        @mixin type-scale $type-tiny, 4;
        height: calc($unit * 9);
        line-height: calc($unit * 9);
        padding: 0 calc($unit * 5);

        @media (--screen-from-med) {
            @mixin type-scale $type-small, 4;
            height: calc($unit * 11);
            line-height: calc($unit * 11);
            padding: 0 calc($unit * 7);
        }
    }

    &--lrg {
        @mixin type-scale $type-small, 4;
        height: calc($unit * 10);
        line-height: calc($unit * 10);
        padding: 0 calc($unit * 5);

        @media (--screen-from-med) {
            height: calc($unit * 13);
            line-height: calc($unit * 13);
            padding: 0 calc($unit * 10);
        }
    }

    /**
     * Spacer - a placeholder for text between
     * two or more buttons
     */
    &-spacer {
        padding: 0 calc($unit);
        @mixin type-scale $type-tiny, 4;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        color: color(white blackness(+50%));
    }

    /**
    * Other styles
    */
    &--wide {
        width: 100%;
    }

    &--whitepaper {
        @mixin type-scale $type-tiny, 4;
        height: calc($unit * 12);
        line-height: calc($unit * 12);
        padding: 0 calc($unit * 5);

        border-color: color($color-primary a(0.25)); 
    }
}
