/*------------------------------------*\
    # base.typography
\*------------------------------------*/

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: $type-weight-regular;
    font-family: $ff-sans-wide;
}
h1 {
    @mixin type-scale $type-xxxlarge, 19;
}
h2 {
    @mixin type-scale $type-xxlarge, 14;
}
h3 {
    @mixin type-scale $type-xlarge, 12;
}
h4, h5, h6 {
    @mixin type-scale $type-large, 10;
}

/**
 * Paragraphs
 */
p {
    margin: 0;
}

/**
 * Lists (ol, ul, dd)
 */
ol, ul, dl {
    margin: 0;
    padding: 0;
    list-style: none;
}
ol {}
ol li {}
ul {}
ul li {}
dd {}
dl dt {}
dl dd {}

/**
 * Anchors
 */
a {
    color: $color-primary;
    text-decoration: none;

    &:link {}
    &:hover {
        color: color($color-primary blackness(+25%));
    }
    &:focus {
        color: color($color-primary blackness(+25%));
    }
    &:visited {}
}

/**
 * Typographic details
 */
hr {
    padding: calc($unit * 2) 0;
    border: 0;
    border-bottom: 1px solid color(white blackness(+15%));
}
em {}
b, strong {
    font-weight: $type-weight-bold;
}
address {}
small {}
pre {}
code {}
sub {}
sup {}
strike {}

/**
 * Tables
 */
table {
    th {
        text-align: left;
    }
}