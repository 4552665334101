/*------------------------------------*\
    # views.enterprise
\*------------------------------------*/

.enterprise {
}

.enterprise__item {
    @media (--screen-from-med) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    }
}

.enterprise__item__content {
    grid-column: 1/7;
}

.enterprise__item__icon {
    grid-column: 10/13;
}
