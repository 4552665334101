/*------------------------------------*\
    # views.blog
\*------------------------------------*/

.blog {
    padding: calc($unit * 40) 0;
}

.blog__featured {
    padding-bottom: calc($unit * 10);

    @media (--screen-from-lrg) {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        padding-bottom: calc($unit * 40);
    }
}

.blog__featured__info {
    grid-row: 1;
    grid-column: 1/11;

    @media (--screen-from-lrg) {
        grid-column: 1/9;
        grid-row: 1;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.blog__featured__title {
    color: white;
    @mixin type-scale $type-xlarge, 12;
    font-family: $ff-sans-wide;
    font-weight: 900;
    text-transform: uppercase;

    @media (--screen-from-xlrg) {
        font-size: 96px;
        line-height: 96px;
    }

    @media (--screen-from-med) {
        @mixin type-scale $type-xxxlarge, 19;
    }
}

.blog__featured__excerpt {
    max-width: 532px;
}

.blog__latest-image {
    min-height: 300px;
    grid-column: 1/11;
    grid-row: 2;
    z-index: 1;

    @media (--screen-from-lrg) {
        grid-column: 6/11;
        grid-row: 1;
        min-height: 872px;
    }
}

.blog__latest-image__image {
    height: 100%;
    object-fit: cover;
}

.blog__featured__date {
    letter-spacing: 0.5em;
}

.blog__cta {
    stroke: white;
    transition: all 0.2s ease-out;

    &:hover,
    &:focus {
        stroke: white;
        fill: rgba(255, 255, 255, 0.1);
    }
}

.blog__grid {
    @media (--screen-from-med) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 32px;
        grid-row-gap: 80px;
    }
}

.blog__article {
    & + & {
        @media (--screen-to-med) {
            margin-top: calc($unit * 10);
        }
    }
}

.blog__article__image {
    max-height: 360px;
    object-fit: cover;
    overflow: hidden;
    display: block;

    @media (--screen-to-med) {
        margin-left: calc($unit * -6);
        width: calc(100% + calc($unit * 12));
    }

    img {
        width: 100%;
    }
}

.blog__article__title {
    color: white;
    transition: all 0.2s ease-out;

    &:hover,
    &:focus {
        color: $color-primary;
    }
}

.blog__article__date {
    letter-spacing: 0.5em;
    margin-top: calc($unit * 6);
}

.blog__cta {
    display: block;
}
