/*------------------------------------*\
    # components.to-top-bar
\*------------------------------------*/

.to-top {
    display: block;
    position: relative;

    top: 50%;
    transform: translateY(-50%);
    width: 60px;

    @media (--screen-to-lrg) {
        display: none;
    }

    .to-top__item {
        display: block;
        margin-top: -1px;

        &:focus {
            outline: 0;
        }
    }

    &__wrapper {
        @media (--screen-from-lrg) {
            position: absolute;
            z-index: 3;
            padding-right: calc($unit * 8);
            height: 100vh;
            right: 0;
        }

        @media (--screen-from-xlrg) {
            padding: calc($unit * 15);
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: 25% 25%;
        display: inline-block;
        vertical-align: middle;
        stroke: white;
        transition: all 0.15s ease-in-out;

        &--top {
            transform: rotate(180deg);
        }
    }

    &__item {
        position: relative;
        display: inline-block;
        z-index: 5;
        text-align: center;
        border: 1px solid #3f414c;
        background: color($color-bg-alt a(0.8));
        transition: all 0.15s ease-in-out;
        height: 50px;
        width: 50px;
        cursor: pointer;

        @media (--screen-from-lrg) {
            height: 60px;
            width: 60px;
        }

        &__label {
            display: block;
            position: absolute;
            top: -1px;
            right: 48px;
            width: 0;
            height: 50px;
            color: $color-primary;
            cursor: pointer;

            padding: calc($unit * 4) 0;

            border: 1px solid #3f414c;
            background: $color-bg-alt;
            
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            transition: all 0.15s ease-in-out;
            
            @mixin type-scale $type-tiny, 4;
            letter-spacing: .2em;
            white-space: nowrap;

            @media (--screen-from-lrg) {
                height: 60px;
                right: 58px;
                padding: calc($unit * 5) 0;
            }
        }

        &:hover {
            .to-top__icon {
                stroke: $color-primary;
            }

            .to-top__item__label {
                padding-left: calc($unit * 6);
                padding-right: calc($unit * 6);
                width: auto;
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
