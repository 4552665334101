/*------------------------------------*\
    # modules.input
\*------------------------------------*/

.input {
    /**
     * Input base
     */
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;
    width: 100%;

    -webkit-border-radius: 0; /* Prefixed to remove border radius on iOS */

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    font-family: $ff-sans;
    background: none;
    border-bottom: 1px solid color(white a(0.2));
    color: white;

    &:focus {
        border-color: $color-primary;
        outline: 0;

        &::placeholder {
            color: $color-primary;
        }
    }

    /**
     * input sizes
     */
    &--sml {
        @mixin type-scale $type-small, 4;
        height: calc($unit * 6);
        line-height: calc($unit * 6);
    }

    &--med {
        @mixin type-scale $type-small, 4;
        height: calc($unit * 11);
        line-height: calc($unit * 11);
    }

    &--lrg {
        @mixin type-scale $type-base, 6;
        height: calc($unit * 13);
        line-height: calc($unit * 13);
    }

    /**
     * input types
     */

    &--text {
    }

    &--number {
        padding-right: 0;
    }

    &--text {
    }

    &--select {
        display: inline-block;
        width: auto;
        background: transparent;
        @mixin type-scale $type-small, 4;
        letter-spacing: 1px;
        opacity: 1;
        color: white;
        border-bottom: 0;
        text-transform: uppercase;
        font-family: $ff-sans-wide;

        &--multiple {
            min-height: 160px;
        }
    }

    &--textarea {
        min-height: 160px;
        resize: vertical;
        border: 1px solid color(white blackness(+30%));
        padding: 0 calc($unit * 2);
    }

    &--check,
    &--radio {
        position: absolute;
        left: -99999px;
        opacity: 0;

        /* input[type="hidden"] is added since MVC.NET
         * generates a hidden field after the input element
         */
        & + input[type='hidden'] + label,
        & + input[type='hidden'] + input[type='hidden'] + label {
            padding-left: calc($unit * 2);

            &:before {
                content: '';
                display: block;
                float: left;
                width: calc($unit * 2.5);
                height: calc($unit * 2.5);
                margin-top: calc($unit / 2);

                transition: all 0.2s ease-in-out;

                border: 1px solid color(white blackness(+30%));
            }
        }

        &:focus {
            & + input[type='hidden'] + label,
            & + input[type='hidden'] + input[type='hidden'] + label {
                &:before {
                    box-shadow: 0 0 5px $color-primary;
                }
            }
        }

        &:checked {
            & + input[type='hidden'] + label,
            & + input[type='hidden'] + input[type='hidden'] + label {
                &:before {
                    background: $color-primary;
                    border-color: transparent;
                }
            }
        }
    }

    &--radio {
        & + input[type='hidden'] + label,
        & + input[type='hidden'] + input[type='hidden'] + label {
            &:before {
                border-radius: 50%;
            }
        }
    }
}
