/*------------------------------------*\
    # modules.logo
\*------------------------------------*/

.logo {
    width: 84px;

    @media (--screen-from-med) {
        width: 102px;
    }

    &--footer {
        fill: red;
    }
}
