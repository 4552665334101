/*------------------------------------*\
    # modules.section
\*------------------------------------*/

.section {
    padding: calc($unit * 15) 0;

    @media (--screen-from-med) {
        padding: calc($unit * 50) 0;
    }

    &--light {
        background: white;
        color: $color-text;

        .u-type--light-color {
            color: color($color-text-light blackness(+60%));
        }

        background-image: url(../../main/assets/images/background/background-grid-dark-single.svg);
        background-repeat: no-repeat;
        background-position: center 50%;
        background-attachment: fixed;
        background-size: contain;
    }

    &--dark {
        /*background: $color-bg-alt;*/
        position: static !important;

        @media (--screen-to-med) {
            background-image: url(../../main/assets/images/background/blob-dark.jpg);
            background-attachment: fixed;
            background-repeat: no-repeat;
            background-position: center calc(50vh - 250px);
            background-size: contain;
        }
    }
}
