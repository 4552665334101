/*------------------------------------*\
    # utils.spacers
\*------------------------------------*/

.u-spc {
    &-top {
        &--tny {
            margin-top: calc($unit * 1);
        }

        &--sml {
            margin-top: calc($unit * 2);
        }

        &--med {
            margin-top: calc($unit * 4);
        }

        &--lrg {
            margin-top: calc($unit * 8);
        }

        &--xlrg {
            margin-top: calc($unit * 12);
        }
    }

    &-right {
        &--tny {
            margin-right: calc($unit * 1);
        }

        &--sml {
            margin-right: calc($unit * 2);
        }

        &--med {
            margin-right: calc($unit * 4);
        }

        &--lrg {
            margin-right: calc($unit * 8);
        }

        &--xlrg {
            margin-right: calc($unit * 12);
        }
    }

    &-bottom {
        &--none {
            margin-bottom: 0;
        }

        &--tny {
            margin-bottom: calc($unit * 1);
        }

        &--sml {
            margin-bottom: calc($unit * 2);
        }

        &--med {
            margin-bottom: calc($unit * 4);
        }

        &--lrg {
            margin-bottom: calc($unit * 8);
        }

        &--xlrg {
            margin-bottom: calc($unit * 12);
        }
    }

    &-left {
        &--tny {
            margin-left: calc($unit * 1);
        }

        &--sml {
            margin-left: calc($unit * 2);
        }

        &--med {
            margin-left: calc($unit * 4);
        }

        &--lrg {
            margin-left: calc($unit * 8);
        }

        &--xlrg {
            margin-left: calc($unit * 12);
        }
    }
}
