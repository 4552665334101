/*------------------------------------*\
    # views.blog-post
\*------------------------------------*/

.blog-post {
    padding: calc($unit * 40) 0;
}

.blog-post__title {
    font-weight: 900;
    font-weight: $type-weight-ultra;
    font-family: $ff-sans-wide;
    text-transform: uppercase;
    @mixin type-scale $type-xxxlarge, 19;
}

.blog-post__content {
    padding: calc($unit * 10) 0;

    @media (--screen-from-med) {
        padding: calc($unit * 20) 0;
    }
}

.blog-post__footer {
    display: flex;
    justify-content: flex-start;
    color: white;

    padding: calc($unit * 5) 0;
    margin-top: calc($unit * 5);
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    @media (--screen-from-med) {
        margin-top: calc($unit * 20);
        padding: calc($unit * 10) 0;
    }
}

.blog-post__share {
    margin-left: auto;
}

.blog-post__date {
    letter-spacing: 0.1em;
}

.blog-post__action {
    cursor: pointer;
    padding: calc($unit * 2);

    &:hover,
    &:focus {
        svg {
            fill: $color-primary;
        }
    }
    svg {
        fill: white;
    }
}
