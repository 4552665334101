/*------------------------------------*\
    # modules.titles
\*------------------------------------*/

.title {
    &--narrow {
        max-width: 16em;
    }
    &--centered {
        margin-left: auto;
        margin-right: auto;
    }
}