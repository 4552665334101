/*------------------------------------*\
    # modules.table
\*------------------------------------*/

.table {
    overflow-x: auto;
    overflow-y: hidden;

    /**
     * Table base
     */

    thead {
        border-bottom: 1px solid black;
    }

    th,
    td {
        vertical-align: top;
        padding: calc($unit * 2);
        @mixin type-scale $type-small, 1;
    }

    tr {
        border-bottom: 1px solid black;

        &:last-child {
            border-bottom: 1px solid black;
        }
    }
}