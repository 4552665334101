/*------------------------------------*\
    # utils.position
\*------------------------------------*/

.u-pos {

    &--rel {
        position: relative;
    }

    &--absolute {
        position: absolute;
    }
    
}