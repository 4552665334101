/*------------------------------------*\
    # views.testnet
\*------------------------------------*/

.testnet {
    padding: calc($unit * 20) 0;

    @media (--screen-from-med) {
        padding: calc($unit * 40) 0;
    }
}

/* Remove default autofill color and use this instead */
.testnet__input:-webkit-autofill,
.testnet__input:-webkit-autofill:hover,
.testnet__input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset; /* Sets background color */
    -webkit-text-fill-color: white; /* Sets text color */
    transition: background-color 5000s ease-in-out 0s; /* Background color change is working because of this transition */
}

.testnet__success-link {
    color: $color-success-light;
    text-decoration: underline;
}
