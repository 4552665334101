/*------------------------------------*\
    # components.converter
\*------------------------------------*/

.converter {
    text-align: center;
    color: white;
    padding-top: calc($unit * 10);

    @media (--screen-from-med) {
        lost-center: 8/12;
        padding-top: calc($unit * 30);
    }

    @media (--screen-from-xlrg) {
        lost-center: 1/2;
    }
}
