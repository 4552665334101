/*------------------------------------*\
    # components.features
\*------------------------------------*/

.features {
    position: relative;
    z-index: 6;
    padding: calc($unit * 16) 0;

    @media (--screen-from-med) {
        padding: calc($unit * 57) 0 calc($unit * 78) 0;
    }

    &__content-wrapper {
    }

    &__widget-wrapper {
        padding-bottom: calc($unit * 62);

        @media (--screen-from-med) {
            gap: 12px;
            lost-flex-container: row;
            flex-direction: column;
        }

        @media (--screen-from-xxlrg) {
            lost-flex-container: row;
            lost-center: 2/3;
            flex-direction: column;
        }

        @media (--screen-to-med) {
            lost-flex-container: row;
            padding-bottom: calc($unit * 12);
        }
    }

    &__widget {
        width: 620px;
        margin-left: -16px !important;

        @media (--screen-from-sml) and (--screen-to-med) {
            lost-column: 8/12 flex;
            lost-move: 2/12;
        }

        &__title {
            padding-top: 16px;

            span {
                display: block;
            }

            @media (--screen-from-sml) and (--screen-to-med) {
                lost-column: 8/12 flex;
                lost-move: 2/12;
            }
        }
    }

    &__content {
        @media (--screen-from-med) {
            lost-flex-container: row;
        }

        @media (--screen-from-xxlrg) {
            lost-flex-container: row;
            lost-center: 2/3;
        }

        &__item {
            margin-bottom: calc($unit * 17);

            &:nth-child(4) {
                margin-bottom: 0;
            }

            @media (--screen-from-sml) and (--screen-to-med) {
                lost-column: 8/12 flex;
                lost-move: 2/12;
            }

            @media (--screen-from-med) {
                lost-column: 5/12 flex;
                margin-bottom: calc($unit * 0);

                &:nth-child(1),
                &:nth-child(2) {
                    margin-bottom: calc($unit * 48);
                }

                &:nth-child(2),
                &:nth-child(4) {
                    lost-move: 1/6;
                }
            }

            img {
                margin-bottom: calc($unit * 8);
                /*filter: invert(1) grayscale(1) contrast(0.5);*/

                @media (--screen-to-med) {
                    max-width: 180px;
                }
            }

            /*.light & img {
                filter: none;
            }*/

            &:nth-child(2),
            &:nth-child(4) {
                @media (--screen-from-lrg) {
                    lost-move: 1/6;
                }
            }

            &__title {
                margin-bottom: calc($unit * 4);

                @media (--screen-from-med) {
                    margin-bottom: calc($unit * 8);
                }
            }
        }
    }
}
