/*------------------------------------*\
    # modules.map-marker
\*------------------------------------*/

.map__marker {
    width: 18px;
    height: 18px;
    display: inline-block;
    background: rgba(255,255,255,0.2);
    border-radius: 9px;
    padding: 2px 6px;

    &__dot {
        width: 6px;
        height: 6px;
        background: white;
        display: inline-block;
        border-radius: 3px;
    }
}