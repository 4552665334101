/*------------------------------------*\
    # utils.float
\*------------------------------------*/

.u-float {

    &--left {
        float: left;
    }

    &--right {
        float: right;
    }
    
}