/*------------------------------------*\
    # components.footer
\*------------------------------------*/

.footer {
    padding: calc($unit * 22) 0;
    border-top: 1px solid rgba(216, 216, 216, 0.1);

    position: relative;
    z-index: 2;

    &__nav {
        & > a {
            display: list-item;
            padding-bottom: calc($unit * 3);
            list-style-type: none;
        }
    }

    &__grid {
        lost-utility: clearfix;

        &__branding {
            margin-bottom: calc($unit * 10);

            @media (--screen-from-xlrg) {
                lost-column: 2/12;
            }
        }

        &__social {
            @media (--screen-to-med) {
                lost-column: 1;
                margin-bottom: calc($unit * 8);
            }

            @media (--screen-from-med) {
                lost-column: 8/12;
            }

            @media (--screen-from-xlrg) {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding-right: calc($unit * 10);
                lost-column: 8/12;
            }
        }

        &__copyright {
            @media (--screen-to-med) {
                lost-column: 1;
            }

            @media (--screen-from-xlrg) {
                lost-column: 2/12;
            }
        }
    }
}
