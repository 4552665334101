/*------------------------------------*\
    # modules.list
\*------------------------------------*/

.list {
    &--icon {
        li {
            list-style-type: none;
            list-style-position: inside;
            position: relative;
            padding: calc($unit * 4) 0;
            padding-left: calc($unit * 12);
            font-weight: $type-weight-bold;
            text-align: left;

            &:before {
                content: '';
                height: 30px;
                width: 30px;
                background: url('main/assets/icons/list-custom-bullet.svg');
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }
        }
    }

    &--bordered {
        & > * {
            padding: calc($unit * 2) 0;
            border-bottom: 1px solid color(white blackness(+20%));
        }
    }

    &--inline {
        @mixin clearfix;

        & > * {
            float: left;
            margin-right: calc($unit * 2);
        }
    }

    &--commaseparated {
        & > * {
            display: inline;

            &:after {
                content: ',';
            }

            &:last-child {
                &:after {
                    content: '';
                }
            }
        }
    }
}
