/*------------------------------------*\
    # components.header
\*------------------------------------*/

.header {
    position: fixed;
    z-index: 10;
    width: 100%;
    transition: background .4s ease-in-out;

    transform: translate3d(0,0,0);

    background: color($color-bg-alt a(.95));

    .light & {
        background: color(white a(.95));
    }

    /* Animation defaults */
    transform: translateY(-100%);

    will-change: transform;
}
