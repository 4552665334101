.download {
    @media (--screen-from-xlrg) {
        padding: 0 calc($unit * 12);
    }
}

.download__header {
    margin: calc($unit * 8) auto 0 auto;
}

.download__wrapper {
    @media (--screen-from-xlrg) {
        display: flex;
    }
}

.download__mainnet {
    @media (--screen-from-xlrg) {
        lost-column: 1/2 2;
    }

    margin-top: calc($unit * 20);
}

.download__testnet {
    max-width: 860px;
    margin-left: auto;
    margin-right: auto !important;

    @media (--screen-from-xlrg) {
        lost-column: 2/2 1;
    }

    margin-top: calc($unit * 20);
}

.download__os {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom-width: 0;

    padding: calc($unit * 6) calc($unit * 11);

    &:last-child {
        border-bottom-width: 1px;
    }

    .download__testnet & {
        @media (--screen-from-xlrg) {
            /*border-left-width: 0px;*/
        }
    }
}

.download__os__wrapper {
    @media (--screen-from-med) {
        display: flex;
    }
}

.download__btn {
    @media (--screen-to-med) {
        margin-top: calc($unit * 3);
    }
}

.download__os__logo {
    margin-top: -6px;
    margin-right: 8px;
}

.download__os__title {
    @mixin type-scale $type-small, 5;
    @media (--screen-from-med) {
        @mixin type-scale $type-base, 7;
    }

    font-family: $ff-sans-wide;
    color: white;
}

.download__os__note {
    padding-top: 12px;
    padding-right: 12px;
}
